<template>
  <div class="goodsListV1">
    <div class="goodsListV1_header">
      <checkedV1 :value="checked.length == list.length" @change="onCheckedAll">
        <span class="goodsListV1_header_title">{{ title }}</span>
      </checkedV1>
      <div class="goodsListV1_header_edit" @click="delButton">{{delTitle}}</div>
    </div>
    <div class="goodsListV1_content">
      <checkedV1
        class="box-m-t-20"
        v-for="(item, index) in list"
        :value="checked.indexOf(item.cart_id) >= 0"
        :key="index"
        :id="index"
        :params="{ item, index }"
        @change="onChecked"
      >
        <goodsV3
          class="goodsListV1_item box-p-l-9"
          :img_url="item.cover"
          :description="item.title"
          :rule="item.spec_title"
          :text="
            item.deduct > 0
              ? item.deduct + '人生券可抵扣' + item.deduct + '元'
              : ''
          "
          :params="item"
          @onRuleClick="onRuleClick(index)"
          @onClick="onGoodsClick"
        >
          <div class="l-flexh l-flexm-b l-flexci-c box-m-t-8">
            <div class="price">
              <span>￥{{ item.market_price }}</span>
              <span>￥{{ item.product_price }}</span>
            </div>
            <div class="numberbox">
              <stepperV1
                :default-value="item.number"
                :min="1"
                :max="item.stock_number"
                :params="{ index: index }"
                @onChange="onStep"
              ></stepperV1>
            </div>
          </div>
        </goodsV3>
      </checkedV1>
    </div>
    <div class="goodsListV1_foot l-flexh l-flexm-b">
      <checkedV1 :value="checked.length == list.length" @change="onCheckedAll">
        <span class="goodsListV1_foot_select">全选</span>
      </checkedV1>
      <div class="goodsListV1_foot_right" v-if="delStatus==0">
        <div>
          <div>总计：￥{{ total_price }}</div>
          <div>
            <span @click="showDiscountDetail = true">优惠明细</span>
            <span>优惠券减{{ save_money }}元</span>
          </div>
        </div>
        <button @click.stop="goBuy">去结算</button>
      </div>
      <div class="goodsListV1_foot_right" v-if="delStatus==1">
        <button @click.stop="switchActionSheet">删除</button>
      </div>

    </div>
    <nut-actionsheet :is-visible="isVisible"
                     :menu-items="menuItems"
                     chooseTagValue="确定"
                     cancelTxt="取消"
                     @close="switchActionSheet"
                     @choose="deleteCarts"
    >
      <span slot="title"><label>确认将商品删除么？</label></span>
     <!-- <span slot="sub-title">删除之后不能，此商品在该购物车中不存在!</span>-->
    </nut-actionsheet>
    <discountV1
      style="bottom: 0"
      :show="showDiscountDetail"
      :info="discount"
      @onClose="showDiscountDetail = false"
    ></discountV1>
  </div>
</template>

<script>
import goodsV3 from "@T/community/goods/goodsV3";
import stepperV1 from "@T/community/stepper/stepperV1.vue";
import checkedV1 from "@T/community/checked/checkedV1.vue";
import discountV1 from "@T/community/popup/discountV1.vue";
export default {
  name: "goodsListV1",
  components: {
    goodsV3,
    stepperV1,
    checkedV1,
    discountV1,
  },
  props: {
    title: {
      type: String,
      default: "自提商品",
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    checked: {
      type: Array,
      default() {
        return [];
      },
    },
    params: null,
  },
  data() {
    return {
      showDiscountDetail: false,
      delTitle:'编辑',
      delStatus:0,
      isVisible:false,
      menuItems: [
        {
          'name': '确定'
        }
      ]
    };
  },
  computed: {
    discount() {
      if (this.checked.length == 0) {
        return {
          total_product_price: 0, //商品总市场价格
          total_market_price: 0, //商品总售价
          save_money: 0, //立减的金额（商品总市场价格 - 商品总售价）
          can_use_renshengquan: 0, //可以使用的人生券数量
          total_save_money: 0, //总优惠金额
        };
      } else {
        return {
          total_product_price: this.product_price, //商品总市场价格
          total_market_price: this.market_price, //商品总售价
          save_money: (this.product_price - this.market_price).toFixed(2), //立减的金额（商品总市场价格 - 商品总售价）
          can_use_renshengquan: this.save_money, //可以使用的人生券数量
          total_save_money:
            (this.product_price - this.market_price + this.save_money).toFixed(2), //总优惠金额
        };
      }
    },
    product_price() {
      let sum = 0;
      //总市场价
      if (this.checked.length == 0) {
        return sum;
      } else {
        this.checked.forEach((val) => {
          let item = this.list.find((good) => {
            return good.cart_id == val;
          });
          let goodsPrice = this.$utils.math.mul(
            item.product_price,
            item.number
          );
          sum = this.$utils.math.add(sum, goodsPrice);
        });
        // this.$utils.math.add();
        // this.$utils.math.mul();
        return sum;
      }
    },
    market_price() {
      let sum = 0;
      //总售价
      if (this.checked.length == 0) {
        return sum;
      } else {
        this.checked.forEach((val) => {
          let item = this.list.find((good) => {
            return good.cart_id == val;
          });
          let goodsPrice = this.$utils.math.mul(item.market_price, item.number);
          sum = this.$utils.math.add(sum, goodsPrice);
        });
        // this.$utils.math.add();
        // this.$utils.math.mul();
        return sum;
      }
    },
    total_price() {
      if (this.checked.length == 0) {
        return 0;
      }
      return this.$utils.math.sub(this.market_price, this.save_money);
    },
    save_money() {
      //总优惠
      let sum = 0;
      if (this.checked.length == 0) {
        return 0;
      } else {
        this.checked.forEach((val) => {
          let item = this.list.find((good) => {
            return good.cart_id == val;
          });
          let goodsDeduct = this.$utils.math.mul(item.deduct, item.number);
          sum = this.$utils.math.add(sum, goodsDeduct);
        });
        return sum;
      }
    },

  },
  mounted() {},
  methods: {
    onGoodsClick() {
      console.log("onClickGoods");
    },
    onRuleClick(index) {
      // console.log("onRuleClick", index);
      this.$emit("onRuleClick", { ...this.$props.params, index: index });
    },
    /**
     * 全选和全部反选
     */
    onCheckedAll() {
      this.$emit("onCheckedAll", this.$props.params);
    },
    /**
     * 单选
     */
    onChecked(params) {
      console.log("111", params);
      this.$emit("onChecked", {
        ...this.$props.params,
        ...params,
      });
    },
    delButton() {
      if (this.delStatus==0){
        this.delStatus=1
        this.delTitle='完成'
      }else{
        this.delStatus=0
        this.delTitle='编辑'
      }
    },
    /**
     * 购买数量
     * 返回改变后的值
     */
    onStep(e) {
      let item = this.$props.list[e.params.index];
      // console.log(e, item);
      //返回改变后的值，额外参数，下表，当前item
      this.$emit("onStep", {
        ...this.$props.params,
        value: e.value,
        index: e.params.index,
        item: item,
      });
    },
    goBuy() {
      let self = this;
      this.$utils.debounce(() => {
        self.$emit("goBuy", {
          ...self.$props.params,
        });
      }, 600);
    },
    deleteCarts(){
      let self = this;
      self.$emit("delCart", {
        ...self.$props.params,
      });
    },
    switchActionSheet(){
      this.isVisible = !this.isVisible;
    }
  },
};
</script>
<style lang="scss" scoped>
@include b(goodsListV1) {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0;
  @include e(item) {
    width: 315px;
  }
  @include e(header) {
    display: flex;
    align-items: center;
    @include e(title) {
      margin-left: 9px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    @include e(edit) {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #F46A17;
      margin-left: auto;

    }
  }
  @include e(content) {
    width: 100%;
    &:not(:last-of-type) > .split {
      width: 100%;
      height: 0px;
      border-bottom: 1px solid #ebebeb;
    }
  }
  @include e(foot) {
    margin-top: 22px;
    display: flex;
    align-items: center;
    height: 56px;
    border-top: 1px solid #ebebeb;
    @include e(select) {
      margin-left: 8px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    @include e(right) {
      display: flex;
      align-items: center;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        & > div:first-of-type {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        & > div:last-of-type {
          display: flex;
          align-items: flex-end;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #eb6100;
          & > span:first-of-type {
            padding: 3px 6px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ebebeb;
            border-radius: 8px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #939393;
          }
        }
      }
      & > button {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        width: 82px;
        height: 36px;
        background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
        border-radius: 18px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
@include b(numberbox) {
  height: 16px;
  display: flex;
  align-items: center;
}
@include b(price) {
  display: flex;
  align-items: flex-end;
  & > span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #c10000;
    &:last-of-type {
      font-size: 10px;
      font-weight: 400;
      margin-left: 5px;
      color: #a2a2a2;
      text-decoration: line-through;
    }
  }
}
</style>
