<template>
  <div v-if="show" class="discountV1" @click.stop="close" @touchmove.prevent>
    <div class="l-container">
      <div
        class="discountV1_contents l-pa"
        :class="{ 'discountV1_contents-show': classShow }"
      >
        <div class="discountV1_info">
          <img
            :src="require('@STATIC/image/icons/closeFill-gray.png')"
            @click="close"
          />
          <span> 优惠明细 </span>
          <div>
            <span>商品总额</span>
            <span>¥{{ info.total_product_price }}</span>
          </div>
          <div v-if="!hideLijian">
            <span>平台立减</span>
            <span>-¥{{ info.save_money }}</span>
          </div>
          <div v-if="info.can_use_renshengquan != 0">
            <span>人生券抵扣</span>
            <span>-¥{{ info.can_use_renshengquan }}</span>
          </div>
          <div>
            <span>共优惠</span>
            <span>-¥{{ info.total_save_money }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 优惠明细弹窗
 */
export default {
  name: "discountV1",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return {
          total_product_price: 0,
          save_money: 0,
          can_use_renshengquan: 0,
          total_save_money: 0,
        };
      },
    },
    hideLijian:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      classShow: true,
    };
  },
  created() {
    setTimeout(() => {
      this.classShow = this.$props.show;
    }, 1);
  },
  watch: {
    show() {
      setTimeout(() => {
        this.classShow = this.$props.show;
      }, 1);
    },
  },
  computed: {},
  methods: {
    close() {
      this.$emit("onClose", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(discountV1) {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);
  @include e(contents) {
    bottom: 0;
    width: 375px;
    height: 0;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 34px 34px 0px 0px;
    transition: height 600ms ease;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    @include m(show) {
      height: 280px;
    }
  }
  @include e(info) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      top: 22px;
      right: 22px;
      position: absolute;
      width: 20px;
      height: 20px;
    }
    & > span {
      margin-top: 23px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    & > div {
      box-sizing: border-box;
      padding: 0 20px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      & > span:last-of-type {
        font-weight: 600;
      }
      &:last-of-type {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        & > span:last-of-type {
          color: #f46a17;
        }
      }
    }
  }
}
</style>
