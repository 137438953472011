<template>
  <div class="goods">
    <img class="img" :src="img_url" />
    <div class="content">
      <div class="description text-line2">{{ description }}</div>
      <div class="rule">
        <span @click.stop="onRuleClick"> {{ rule }} </span>
      </div>
      <div class="text">
        {{ text }}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsV3",
  props: {
    img_url: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    rule: {
      type: String,
      default: "默认规格",
    },
    text: {
      type: String,
      default: "",
    },
    params: null,
  },
  methods: {
    onRuleClick() {
      this.$emit("onRuleClick", this.$props.param);
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(goods) {
  box-sizing: border-box;
  width: 100%;
  display: flex;
}
@include b(img) {
  flex-shrink: 0;
  width: 109px;
  height: 109px;
  border-radius: 4px;
}
@include b(content) {
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  padding-left: 10px;
}
@include b(description) {
  width: 100%;
  height: 33px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
@include b(rule) {
  display: flex;
  flex-wrap: wrap;
  & > span {
    box-sizing: border-box;
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    margin-top: 8px;
    background: #f6f6f6;
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a2a2a2;
  }
}
@include b(text) {
  margin-top: 8px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f46a17;
}
</style>
