<template>
  <div id="cart">
    <div class="l-boxs box-p-5" v-if="site_points_goods.length > 0"></div>
    <emptyV1
      v-if="site_points_goods.length == 0 && express_goods.length == 0"
    ></emptyV1>
    <div class="l-boxs" v-if="site_points_goods.length > 0">
      <cartV1
        :list="site_points_goods"
        :info="site_points_info"
        :checked="site_points_checked"
        title="自提商品"
        :params="{ type: 1 }"
        @onGoodsClick="onGoodsClick"
        @onCheckedAll="onCheckedAll"
        @onChecked="onChecked"
        @onStep="onStep"
        @onRuleClick="onRuleClick"
        @goBuy="goBuy"
        @delCart="deleteCarts"
      ></cartV1>
    </div>
    <div class="l-boxs box-p-5" v-if="express_goods.length > 0"></div>
    <div class="l-boxs" v-if="express_goods.length > 0">
      <cartV1
        :list="express_goods"
        :info="express_info"
        :checked="express_checked"
        title="梦回楼兰快递配送"
        :params="{ type: 2 }"
        @onGoodsClick="onGoodsClick"
        @onCheckedAll="onCheckedAll"
        @onChecked="onChecked"
        @onStep="onStep"
        @onRuleClick="onRuleClick"
        @goBuy="goBuy"
        @delCart="deleteCarts"
      ></cartV1>
    </div>
    <rulesV1
      :show="rule.show"
      :cover="goodsInfo.cover"
      :unit_price="goodsInfo.market_price"
      :number="goodsInfo.number"
      :stock="goodsInfo.stock_number"
      :goods_products="goodsInfo.goods_products"
      :goods_specs="goodsInfo.goods_specs"
      :goods_spec_checkeds="goodsInfo.goods_spec_checkeds"
      @onClose="rule.show = false"
      @onConfirm="onChangeRule"
    ></rulesV1>

    <nut-tabbar
      @tab-switch="tabberSwitch"
      type="based"
      :tabbar-list="tabList1"
      :bottom="true"
    ></nut-tabbar>
  </div>
</template>

<script>
import cartV1 from "@T/community/cart/cartV1.vue";
import rulesV1 from "@T/community/popup/rulesV1";
import emptyV1 from "../../template/community/empty/emptyV1";
import { mapActions, mapState } from "vuex";
export default {
  name: "cart",
  components: {
    cartV1,
    rulesV1,
    emptyV1,
  },
  created() {
    document.title = "购物车";
    this.initCart();
  },
  data() {
    return {
      tabList1: [
        {
          tabTitle: "首页",
          curr: true,
          icon: "/tabbar/shouye.png",
          activeIcon: "/tabbar/shouyeactive.png",
        },
        {
          tabTitle: "店铺分类",
          curr: false,
          icon: "/tabbar/shouye1.png",
          activeIcon: "/tabbar/shouye2.png",
        },
        {
          tabTitle: "直播间",
          curr: false,
          icon: "/tabbar/zhibojian.png",
          activeIcon: "/tabbar/zhibojianactive.png",
        },
        {
          tabTitle: "购物车",
          curr: true,
          icon: "/tabbar/gouwuche.png",
          activeIcon: "/tabbar/gouwucheactive.png",
        },
        {
          tabTitle: "会员中心",
          curr: false,
          icon: "/tabbar/wode.png",
          activeIcon: "/tabbar/wodeactive.png",
        },
      ],
      rule: {
        type: 1, //1 自提 2快递
        show: false,
      },
      goodsInfo: {
        cart_id: 0,
        cover: "",
        number: "",
        market_price: "",
        goods_products: {},
        goods_specs: [],
        goods_spec_checkeds: {},
      },
    };
  },
  computed: {
    ...mapState("community_cart", [
      "site_points_goods",
      "express_goods",
      "site_points_info",
      "express_info",
      "site_points_checked",
      "express_checked",
    ]),
  },
  methods: {
    ...mapActions("community_cart", [
      "initCart",
      "addCart",
      "delCart",
      "updataCart",
      "changeChecked",
      "changeAllChecked",
    ]),
    ...mapActions("community_order", ["reset"]),
    //单机商品
    onGoodsClick(params) {
      console.log("onGoodsClick", params);
    },
    tabberSwitch(e) {
      console.log(e);
      if (e.tabTitle == "首页") {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: "switchTab",
            url: "/pages/home/index/common",
          });
        }
      } else if (e.tabTitle == "店铺分类") {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: "switchTab",
            url: "/pages/shopClass/index/common",
          });
        }
      } else if (e.tabTitle == "直播间") {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: "switchTab",
            url: "/pages/liveRoom/index/common",
          });
        }
      } else if (e.tabTitle == "会员中心") {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: "switchTab",
            url: "/pages/personCenter/index/commonNew",
          });
        }
      }
    },
    //弹出规则编辑框
    onRuleClick(params) {
      let item = null;
      // console.log("onRuleClick", params);
      if (params.type == 1) {
        item = this.site_points_goods[params.index];
      } else {
        item = this.express_goods[params.index];
      }
      let goods_spec_checkeds = {};
      for (let i = 0; i < item.spec_ids_arr.length; i++) {
        const child = item.spec_ids_arr[i];
        for (let j = 0; j < item.goods_specs.length; j++) {
          const parent = item.goods_specs[j];
          let isHas = parent.spec_items.some((val) => {
            return Number(val.id) == Number(child);
          });
          if (isHas) {
            Object.assign(goods_spec_checkeds, {
              [item.goods_specs[j].id]: Number(child),
            });
            break;
          }
        }
      }
      let goodsInfo = {
        cover: item.cover,
        cart_id: item.cart_id,
        number: item.number,
        market_price: item.market_price,
        goods_products: item.goods_products || {},
        goods_specs: item.goods_specs || [],
        goods_spec_checkeds: goods_spec_checkeds || {},
      };
      this.goodsInfo = goodsInfo;
      this.rule = {
        type: params.type,
        show: true,
      };
    },
    //购物车单选
    onChecked(params) {
      console.log("onChecked", params);
      this.changeChecked({
        type: params.type,
        cardId: params.params.item.cart_id,
      });
    },
    //购物车全选
    onCheckedAll(params) {
      // console.log("onCheckedAll", params);
      this.changeAllChecked(params.type);
    },
    //更新购物车数量
    onStep(params) {
      console.log("onStep", params);
      if (params.value == params.item.number) {
        return;
      }
      this.updataCart({
        type: params.type, //类型 1自提 2快递
        cart_id: params.item.cart_id,
        number: params.value,
        spec_ids: params.item.spec_ids,
      });
    },
    //删除购物车
    deleteCarts(params) {
      let cart_ids = [];
      if (params.type == 1) {
        cart_ids = this.site_points_checked;
      } else {
        cart_ids = this.express_checked;
      }
      if (cart_ids.length == 0) {
        this.$toast.text("请选择删除的商品", {
          duration: 1000,
        });
        return;
      }
      this.delCart({
        type: params.type, //类型 1自提 2快递
        cart_ids: cart_ids,
      });

      console.log(cart_ids, "cart_ids");
    },
    //去购买
    goBuy(params) {
      console.log("goBuy", params);
      this.reset();
      let cart_ids = [];
      if (params.type == 1) {
        cart_ids = this.site_points_checked;
      } else {
        cart_ids = this.express_checked;
      }
      if (cart_ids.length == 0) {
        this.$toast.text("请选择要够买的商品", {
          duration: 1000,
        });
        return;
      }
      let query = {
        type: params.type,
        cart_ids: encodeURI(JSON.stringify(cart_ids)),
      };
      if (this.$platform.wxsdk.isWechat()) {
        query = encodeURIComponent(JSON.stringify(query));
        this.$platform.wxsdk.wxRoute({
          // type: "redirectTo",
          type: "navigateTo",
          url: "/web/community/order-confrim?query=" + query,
        });
      } else {
        this.$router.push({
          path: "order-confrim",
          query: query,
        });
      }
    },
    //更新购物车规则和数量
    onChangeRule(params) {
      // console.log("更新规则", params);
      this.updataCart({
        type: this.rule.type, //类型 1自提 2快递
        cart_id: this.goodsInfo.cart_id,
        number: params.num,
        spec_ids: Object.values(params.specs)
          .sort((a, b) => a - b)
          .join(","),
      });
    },
  },
};
</script>

<style scoped lang="scss">
#cart {
  display: inline-block;
  width: 100%;
  min-height: 100%;
  background: #f6f6f6;
}
@include b(card) {
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  @include m(p20) {
    padding: 20px;
  }
}
@include b(cell) {
  height: 44px !important;
  &:not(:last-of-type) {
    border-bottom: 1px solid #ebebeb;
  }
  @include e(content) {
    box-sizing: border-box;
    padding-left: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}
@include b(description) {
  width: 250px;
  align-self: flex-end;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #939393;
}
/deep/.remark input {
  width: 100%;
  padding-right: 0;
  border: none;
  outline: none;
  text-align: right;
}

.nut-tabbar {
  border-right: 0;
  border-top: 1px solid #ccc8c8;
  background-color: #f7f7fa;
  color: #999999;
  height: 45px;
}

/deep/.curr {
  color: #ff5555 !important;
}

/deep/.icon-box {
  padding: 0;
  display: inline-block;
  position: relative;
  height: 100%;
  line-height: 4px;
  margin-top: 3px;
}
/deep/.icon-box .icon {
  margin: 0 auto;
  width: 22px;
  height: 22px;
  display: block;
  background-size: 100% 100%;
  background-position: center center;
}
.tabbar-nav-word {
  font-size: 12px;
}
</style>
